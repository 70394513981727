import React, { useState } from "react";
import TablesComponent from "../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../generalComponents/Pagination";
import { noImage } from "../../../assets/images";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import useGetBidsManager from "../controllers/getBidsController";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import Countdown from "../../../generalComponents/CountDown";
import { formatNumber } from "../../../utils/formatNumber";

const BidHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);

  const { data, isLoading } = useGetBidsManager({
    page: currentPage,
    participant: true,
    is_active: false,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Lot Name",
    "Bid Amount",
    "Highest Bid",
    "End Date",
    "Ending In",
  ];

  const getFormattedValue = (data, index) => {
    return [
      <span className="flex gap-x-2 items-center">
        <img
          src={data?.lot?.image_url || noImage}
          alt="Logo"
          className="w-10 h-10 rounded-full"
        />
        {data?.lot?.name}
      </span>,
      <span>{formatNumber(data?.amount)}</span>,
      <span>{formatNumber(data?.lot?.highest_bid_amount)}</span>,
      <span> {formatDateAndTime(data?.lot?.end_datetime)}</span>,
      <Countdown endDatetime={data?.lot?.end_datetime} />,
    ];
  };

  return (
    <div className="flex flex-col w-full gap-7">
      <div className="h-[67vh] w-full relative">
        <TablesComponent
          isLoading={isLoading}
          data={data?.data?.results}
          getFormattedValue={getFormattedValue}
          headers={headers}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          toggleRowFunction={() => {}}
          toggleSelectAllFunction={() => {}}
          showCheckBox={false}
          hideActionButton
        />
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {startItem} - {endItem} of {data?.data?.pagination?.count} users
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default BidHistory;
