import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetCartItemsManager = ({
  type,
  page = "",
  enabled = true,
  pageSize = "15",
  is_winner = false,
  isPaid = false,
}) => {
  return useQuery(
    ["cart", page, type, isPaid, is_winner],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/lots/?is_winner=${is_winner}&is_paid=${isPaid}`
          ),
        ];

        //console.log(`i am checking this ${response.data.data.user.role.name}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetCartItemsManager;
