import React, { useState } from "react";
import PaginationRounded from "../../../../generalComponents/Pagination";
import TablesComponent from "../../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import useGetUploadBatchesManager from "../controllers/getUploadBatchesController";
import { calculatePaginationRange } from "../../../../utils/calculatePaginationRange";
import SearchField from "../../../../generalComponents/SearchField";
import useDebounce from "../../../../utils/UseDebounce";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import DownloadStockBatchManager from "../controllers/downloadStockBatchController";

const UploadBatches = ({ id }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [fileName, setFileName] = useState("");
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetUploadBatchesManager({
    dateBefore: dateBefore,
    dateAfter: dateAfter,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
    clientId: id,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const { data: downloadFile } = DownloadStockBatchManager({
    batch_id: batchId,
  });

  const options = [
    {
      label: "View Asset",
      permissions: ["view_stockbatch"],
    },
    { label: "Download File", permissions: ["export_stockbatch"] },
  ];

  const headers = ["S/N", "File Name", "Upload ID", "Upload Date", "Action"];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.file_name,
      el?.batch_id,
      <span>
        {new Date(el?.created_datetime).toLocaleString("en-GB", {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </span>,
    ];
  };

  const handleDownload = () => {
    if (downloadFile && downloadFile instanceof Blob) {
      try {
        const url = window.URL.createObjectURL(downloadFile);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error("Download failed:", err);
      }
    } else {
      console.error("Invalid download data");
    }
  };

  return (
    <div className="mt-2 md:mt-2 flex flex-col w-full gap-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="">
          <SearchField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
        </div>
        <div className="flex items-center space-x-4 justify-center align-middle">
          <span className="text-[14px] ">Select Date Range:</span>

          <InputWithFullBoarder
            className="flex-1"
            type="date"
            value={dateAfter}
            onChange={(e) => {
              setDateAfter(e.target.value);
            }}
          />

          <span className="whitespace-nowrap">to</span>
          <InputWithFullBoarder
            className="flex-1"
            type="date"
            value={dateBefore}
            onChange={(e) => {
              setDateBefore(e.target.value);
            }}
          />
        </div>
        {/* <div className="flex space-x-[10px] mt-3 md:mt-0">
          <ButtonWithIcon
            buttonText={"Download Report"}
            radius={"md"}
            icon={GoDownload}
          />
        </div> */}
      </div>
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            toggleRowFunction={() => {}}
            toggleSelectAllFunction={() => {}}
            popUpFunction={(option, index, val) => {
              console.log(option, index, val);
              setBatchId(val.batch_id);
              if (index === 0) {
                navigate(`/upload-batch/${val.batch_id}`);
              }
              if (index === 1) {
                setBatchId(val.batch_id);
                setFileName(val.file_name);
                handleDownload(val.batch_id);
              }
            }}
            options={options}
            // Close popup function
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default UploadBatches;
