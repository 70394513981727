import React, { useState } from "react";
import PaginationRounded from "../../generalComponents/Pagination";
import { useNavigate } from "react-router-dom";
import BaseDashboardNavigation from "../../generalComponents/BaseDashboardNavigation";
import useDebounce from "../../utils/UseDebounce";
import SearchField from "../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../utils/calculatePaginationRange";
import { noImage } from "../../assets/images";
import TablesComponent from "../../generalComponents/TablesComponent";
import useGetClientsManager from "../accessControl/clients/controllers/getClientsController";

const Disposal = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const navigate = useNavigate();

  const { data, isLoading } = useGetClientsManager({
    type: "OPERATOR",
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );
  const headers = ["Operator", "Actions"];

  const getFormattedValue = (client, index) => {
    return [
      <span className="flex gap-x-10 items-center">
        <img
          src={client?.logo || noImage}
          alt="Logo"
          className="w-10 h-10 rounded-full"
        />
        {client?.name}
      </span>,
    ];
  };

  return (
    <BaseDashboardNavigation>
      <div className="flex flex-col w-full gap-7">
        <div className="flex items-center mb-2 md:mb-0">
          <h3 className="text-[24px] font-bold">Disposal Management</h3>
        </div>
        <div className="w-full flex flex-col gap-7 sticky top-0 bg-[#F8F9FC]">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center gap-3">
              <SearchField
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />{" "}
            </div>
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              toggleRowFunction={() => {}}
              toggleSelectAllFunction={() => {}}
              buttonFunction={(val) => {
                console.log(val);
                navigate(`/disposal/${val.id}`, {
                  state: { operatorName: val.name },
                });
              }}
              // popUpFunction={(option, index, val) => {
              //   console.log(option, index, val);
              //   if (index === 0) {
              //     navigate(`/edit-client/${val?.id}`);
              //   }
              //   // if (index === 3) {
              //   //   document.getElementById("deactivate_modal").showModal();
              //   // }
              // }}
              // options={options}
              showCheckBox={false}
              // Close popup function
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} users
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default Disposal;
