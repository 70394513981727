import React from "react";
import Tabs from "../../../generalComponents/Tabs";
import { hasPermissions } from "../../../constants/permissions";
import ActiveBids from "./ActiveBids";
import BidHistory from "./BidHistory";
import VendorLayout from "../../../generalComponents/VendorLayout";

const VendorBids = () => {
  const tabsData = [
    { label: "Active Bids", component: <ActiveBids /> },
    { label: "Bid History", component: <BidHistory /> },
  ];

  return (
    <VendorLayout>
      <div className="max-w-[1240px] w-full flex flex-col text-blackColor mx-auto">
        <div className="w-full justify-between items-center flex">
          <h3 className="text-28px font-semibold md:mx-0 mx-4">Bids </h3>{" "}
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div
            className={`${hasPermissions(["add_client"]) ? "flex" : "hidden"} `}
          >
            {" "}
          </div>
        </div>
        <Tabs tabsData={tabsData} />
      </div>
    </VendorLayout>
  );
};
export default VendorBids;
