import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetDisposalBatchesManager = ({
  searchQuery,
  page,
  enabled = true,
  pageSize = "15",
  date_after = "",
  date_before = "",
  clientId = "",
}) => {
  return useQuery(
    ["disposals", page, date_after, date_before, clientId, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/stock/disposals/?client=${clientId}&date_after=${date_after}&date_before=${date_before}&page=${page}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetDisposalBatchesManager;
