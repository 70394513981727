import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useLotExportManager = (id) => {
  return useQuery(
    ["lots", id],
    async () => {
      try {
        const response = await AxiosWithToken.get(`/lots/${id}/items/export/`, {
          responseType: "blob",
        });
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      enabled: !!id,
    }
  );
};

export default useLotExportManager;
