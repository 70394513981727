import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const PostRemitaLotsTransaction = () => {
  const { postCaller, isLoading, isSuccess, error, data } = usePostManager(
    `/lots/transactions/remita/initiate/`,
    ["lots"],
    true
  );
  const initiateLotRemita = async (details) => {
    try {
      await postCaller(details);
      return data;
    } catch (error) {
      console.error("error:", error);
    }
  };
  return {
    initiateLotRemita,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
