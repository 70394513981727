import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import useDebounce from "../../../utils/UseDebounce";
import useGetTransactionsManager from "../controllers/getVendorTransactionsController";
import { formatNumber } from "../../../utils/formatNumber";
import StatusButton from "../../../generalComponents/StatusButton";
import DropdownandSearch from "../../../generalComponents/DropdownandSearch";
import VendorLayout from "../../../generalComponents/VendorLayout";

const VendorTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetTransactionsManager({
    page: currentPage,
    searchQuery: debouncedSearchValue,
    status: status,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Transaction Ref.",
    "Purpose",
    "Method",
    "Bank Name",
    "Amount",
    "Payment Date",
    "Status",
  ];

  const getFormattedValue = (el, index) => {
    return [
      el?.reference,
      el?.source,
      el?.gateway || "Bank Transfer",
      el?.sender_bank_name || "N/A",
      <span>{formatNumber(el?.amount)}</span>,
      el?.payment_date,
      <StatusButton status={el?.status} />,
    ];
  };

  const handleStatusSelect = (event) => setStatus(event.target.value);

  const statusOptions = [
    { value: "", label: "All" },
    { value: "PENDING", label: "Pending" },
    { value: "CONFIRMED", label: "Confirmed" },
    { value: "REJECTED", label: "Rejected" },
  ];

  return (
    <VendorLayout>
      <div className="flex flex-col w-full gap-7">
        <div className="w-full flex flex-col gap-7 sticky top-0 bg-[#F8F9FC]">
          <div className="flex justify-between items-center">
            <div className="">
              <DropdownandSearch
                options={statusOptions}
                selectedOption={status}
                onSelect={handleStatusSelect}
                dropdownplaceholder="Filter by Status"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            showCheckBox={false}
            hideActionButton
            // Close popup function
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} users
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </VendorLayout>
  );
};

export default VendorTransactions;
