import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetInspectionsManager from "../controllers/getGetInspectionsController";
import TaskReport from "./TaskReport";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";

const CompletedTasks = () => {
  const [selected, setSelected] = useState(null);
  const [setCurrentPage] = useState(1);

  const { data, isLoading } = useGetInspectionsManager({
    is_assigned_staff: true,
    status: "REPORTED",
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = ["S/N", "Title", "Request Date", "Status", "Action"];

  const getFormattedValue = (el, index) => {
    // Check if `el` is an array and retrieve `asset_name` from the first object, or use an empty string as fallback
    const assetName =
      Array.isArray(el?.stocks) && el.stocks[0]?.asset_name
        ? el.stocks[0].asset_name
        : "";
    const titleOrAssetName = el?.title || assetName;
    return [
      index + 1,
      titleOrAssetName,
      <span>{new Date(el?.scheduled_date).toLocaleDateString()}</span>,
      <span>
        <StatusButton status={el?.status} />
      </span>,
    ];
  };

  return (
    <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
      <div className="h-[67vh] w-full relative">
        {
          <TablesComponent
            isLoading={isLoading}
            data={data?.data?.results}
            getFormattedValue={getFormattedValue}
            headers={headers}
            buttonFunction={(val) => {
              setSelected(val.id);
              document.getElementById("task_report").showModal();
            }}
          />
        }
      </div>
      <div className="flex items-center justify-between mt-4">
        <p className="text-14px text-brandBlack">
          {" "}
          {startItem} - {endItem} of {data?.data?.pagination?.count} Items
        </p>
        <PaginationRounded
          defaultPage={data?.data?.pagination?.page}
          count={data?.data?.pagination?.total_pages}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
      <TaskReport inspectionId={selected} />
    </div>
  );
};
export default CompletedTasks;
