import { useNavigate } from "react-router-dom";
import useGetUserDetailsManager from "../modules/settings/controllers/get_UserDetails_controller";
import { useEffect } from "react";
import Disposal from "../modules/disposal/Disposal";

const DisposalRouter = () => {
  const { data: client } = useGetUserDetailsManager();
  const navigate = useNavigate();

  const clientDetails = client?.data?.client;

  useEffect(() => {
    if (clientDetails?.type === "OPERATOR") {
      navigate(`/disposal/${clientDetails?.id}`);
    }
  }, [clientDetails, navigate]);

  return <Disposal />;
};

export default DisposalRouter;
