import React, { useEffect, useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menus } from "../constants/menus";
import { logo, noImage } from "../assets/images";
import { MdKeyboardArrowDown, MdLocalOffer } from "react-icons/md";
import ButtonWithIcon from "./ButtonWithIcon";
import {
  BiCart,
  BiLogIn,
  BiSolidDashboard,
  BiUser,
  BiWallet,
} from "react-icons/bi";
import useGetUserDetailsManager from "../modules/settings/controllers/get_UserDetails_controller";
import { getInitials } from "../utils/getInitials";
import useGetCartItemsManager from "../modules/cart/controllers/getCartItemsController";

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileRef = useRef(null);
  const router = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const isLoggedIn = Boolean(localStorage.getItem("token"));
  const { data: userDetails } = useGetUserDetailsManager(isLoggedIn);

  useEffect(() => {
    const index = menus.findIndex((menu) => menu.path === pathname);
    setCurrentIndex(index !== -1 ? index : 0);
  }, [pathname]);

  const { data: cart } = useGetCartItemsManager({
    is_winner: true,
    isPaid: false,
    enabled: isLoggedIn,
  });

  const cartItems = cart?.data?.results;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const openCloseMenu = () => {
    setOpenMenu(!openMenu);
  };

  const getMenuItems = () => {
    const clientType = localStorage.getItem("client_type");
    const isVendor = clientType === "VENDOR";
    const isAdmin = clientType === "admin";

    if (isAdmin) {
      return [
        {
          title: "Dashboard",
          path: "/dashboard",
          icon: BiSolidDashboard,
        },
        {
          title: "Profile",
          path: "/vendor/profile",
          icon: BiUser,
        },
        {
          title: "My Bids",
          path: "/vendor/bids",
          icon: MdLocalOffer,
        },
        {
          title: "Transactions",
          path: "/vendor/transactions",
          icon: BiWallet,
        },
      ];
    }

    if (isVendor) {
      return [
        {
          title: "Profile",
          path: "/vendor/profile",
          icon: BiUser,
        },
        {
          title: "My Bids",
          path: "/vendor/bids",
          icon: MdLocalOffer,
        },
        {
          title: "Transactions",
          path: "/vendor/transactions",
          icon: BiWallet,
        },
      ];
    }

    return [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: BiSolidDashboard,
      },
    ];
  };

  const menuItems = getMenuItems();

  const handleProfileClick = (e) => {
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };

  const handleMenuItemClick = (path) => {
    router(path);
    setShowProfileMenu(false);
  };

  // Replace ProfileButton component with this JSX directly in the return statement where it's used:
  const profileMenu = (
    <div className="relative" ref={profileRef}>
      <button
        onClick={handleProfileClick}
        className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50"
      >
        <div className="w-10 h-10 rounded-full border-2 border-brandGreen flex items-center justify-center bg-[#EDF6E6]">
          <span className="text-sm font-medium">
            {(
              <img
                className=" object-cover h-[44px] w-[44px] p-[0.7px] rounded-full "
                src={
                  // data?.data?.user?.profile_picture !== ""
                  userDetails?.data?.user?.profile_image_url
                }
                alt="user avatar"
              />
            ) || userDetails?.data?.user?.first_name
              ? getInitials(
                  `${userDetails?.data?.user?.first_name} ${userDetails?.data?.user?.last_name}`
                )
              : noImage}
          </span>
        </div>
        <div className="flex flex-col items-start">
          <span className="font-medium text-sm text-brandGreen">
            {userDetails?.data?.user?.first_name}{" "}
            {userDetails?.data?.user?.last_name}
          </span>
          <span className="text-gray-500 text-xs">
            {userDetails?.data?.user?.email}
          </span>
        </div>
      </button>

      {showProfileMenu && (
        <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg py-1 border z-50">
          {menuItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <button
                key={index}
                onClick={() => handleMenuItemClick(item.path)}
                className="flex items-center w-full gap-3 px-4 py-2 hover:bg-gray-100 text-gray-700"
              >
                <Icon className="w-5 h-5" />
                <span>{item.title}</span>
              </button>
            );
          })}
          <button
            onClick={() => {
              localStorage.clear();
              router("/login");
            }}
            className="flex items-center w-full gap-3 px-4 py-2 hover:bg-gray-100 text-red-600 border-t mt-1"
          >
            <BiLogIn className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );

  // Add this before the profileMenu in the return:
  const cartMenu = (
    <div className="flex items-center mr-4">
      <Link to="/cart">
        <button className="relative p-1 hover:bg-gray-50 rounded-lg">
          <BiCart className="w-8 h-8 text-gray-700" />
          {cartItems?.length > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-700 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {cartItems?.length}
            </span>
          )}
        </button>
      </Link>
    </div>
  );

  return (
    <div className="flex max-w-[1240px] lg:px-5 lg:justify-between lg:border border-b-[#E0E0E0] border-transparent lg:pb-6 items-end lg:mx-auto lg:my-0 text-black">
      <Link to={"/"}>
        <div className="hidden lg:flex mr-2 items-center z-40">
          <img src={logo} alt="logo" className="h-[71.6px] w-auto" />
        </div>
      </Link>

      {/* main menu */}
      <div className="flex flex-col items-start">
        <ul className="hidden lg:flex text-16px font-medium gap-12 lg:items-center mb-1 ml-1">
          {menus.map((menu, index) => (
            <li
              className={
                !menu.forTop
                  ? "hidden"
                  : menu.isHighlighted
                  ? "bg-brandGreen text-white rounded-md px-8 py-2"
                  : pathname === menu.path
                  ? `border-brandGreen border-b-[2px]`
                  : ""
              }
              key={index}
            >
              <button
                onClick={() => {
                  router(menu.path);
                  setCurrentIndex(index);
                }}
              >
                <h3
                  className={`hover:font-semibold duration-500 ${
                    pathname.includes(menu.path) ? "text-brandGreen" : ""
                  } transition flex items-center gap-2`}
                >
                  {menu.title}
                  {menu.title === "Categories" && (
                    <MdKeyboardArrowDown size={24} />
                  )}
                </h3>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {isLoggedIn ? (
        <div className="flex items-center">
          {cartMenu}
          {profileMenu}
        </div>
      ) : (
        <ButtonWithIcon
          buttonText="Log In"
          onClick={() => router("/login")}
          icon={BiLogIn}
        />
      )}

      {/* mobile menu */}
      <div className="fixed px-5 lg:hidden right-0 z-50 bg-white top-0 py-3 flex items-center justify-between w-full">
        <Link to={"/"}>
          <div className="mr-2 items-center z-40">
            <img src={logo} alt="logo" className="h-[45.17px] w-auto" />
          </div>
        </Link>
        <div className="md:text-[30px] text-black" onClick={openCloseMenu}>
          {openMenu ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      <ul
        className={`fixed lg:hidden ${
          openMenu ? "left-0" : "left-[-100%]"
        } top-0 pt-8 pl-8 pr-8 bg-white h-full w-[70%] md:w-[40%] flex flex-col text-[16px] font-normal font-dm-sans space-y-8 items-left ease-in-out duration-500 z-50`}
      >
        <Link to={"/"}>
          <div className="flex mr-2 items-center">
            <img src={logo} alt="logo" className="h-[71.6px]" />
          </div>
        </Link>
        <ul>
          {menus
            .filter((item) => item.title !== "Appointments")
            .map((menu, index) => (
              <li
                className={
                  !menu.forTop
                    ? "hidden"
                    : menu.isHighlighted
                    ? "border border-lemonGreen px-5 py-2 my-2 max-w-max bg-brandGreen text-white rounded-md"
                    : index === currentIndex
                    ? `border border-b-lemonGreen border-transparent border-b-[2px] max-w-max my-4`
                    : "my-4"
                }
                key={index}
              >
                <button
                  onClick={() => {
                    router(menu.path);
                    setCurrentIndex(index);
                    setOpenMenu(false);
                  }}
                >
                  {menu.title}
                </button>
              </li>
            ))}
        </ul>

        {isLoggedIn ? (
          <div className="flex items-center">
            {cartMenu}
            {profileMenu}
          </div>
        ) : (
          <ButtonWithIcon
            buttonText="Log In"
            onClick={() => {
              router("/login");
              setOpenMenu(false);
            }}
            icon={BiLogIn}
          />
        )}
      </ul>
    </div>
  );
};

export default NavBar;
