import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import CustomButton from "../../../generalComponents/Button";
import { CreateLotsManager } from "../controllers/createLotsController";
import { UpdateLotsManager } from "../controllers/updateLotsController";
import TabsManagement from "../../../generalComponents/TabsManagement";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import GoBackButton from "../../../generalComponents/GoBackButton";
import SearchField from "../../../generalComponents/SearchField";
import TablesComponent from "../../../generalComponents/TablesComponent";
import useDebounce from "../../../utils/UseDebounce";
import { noImage } from "../../../assets/images";
import useGetAuctionItemsManager from "../controllers/getAuctionItemsController";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import truncateWithTooltip from "../../../utils/truncateText";
import PaginationRounded from "../../../generalComponents/Pagination";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";

const CreateLot = ({ details }) => {
  const [lotName, setLotName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [requestId, setRequestId] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [isBatchSelected, setIsBatchSelected] = useState(false);
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const navigate = useNavigate();

  const { createLot, isLoading, isSuccess } = CreateLotsManager();

  const { updateLot, isSuccess: updated } = UpdateLotsManager({
    id: details?.id,
  });

  const { data, isLoading: fetchingItems } = useGetAuctionItemsManager({
    searchQuery: debouncedSearchValue,
    enabled: true,
    page: currentPage,
    request_id: requestId,
    status: "LEVEL_THREE_APPROVED",
  });

  const [currentView, setCurrentView] = useState(0);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set image preview
      setImagePreview(URL.createObjectURL(file));

      // Convert to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setImage(base64String); // This will now store the base64 string instead of the file
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBatchSelection = () => {
    if (data?.data?.results) {
      // Get all item IDs from the current data
      const allItemIds = data.data.results.map((item) => item.id);
      setSelectedItems(allItemIds);
      setSelectedRows(data.data.results.map((_, index) => index));
      setSelectedRequestId(requestId);
      setIsBatchSelected(true);
    }
  };

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  // useEffect(() => {
  //   if (details) {
  //     setLotName(details?.name);
  //     setLotType(details?.type);
  //     setStartAmount(details?.starting_amount);
  //     setClosingAmount(details?.closing_amount);
  //   }
  // }, [details]);

  const handleCreateLot = async () => {
    const lotData = {
      image: image,
      name: lotName,
      ...(isBatchSelected
        ? { request_id: selectedRequestId }
        : { items: selectedItems }),
    };
    if (details) {
      updateLot(lotData);
    } else {
      createLot(lotData);
    }
  };

  const headers = [
    "Image",
    "Asset Name",
    "Request ID",
    "Asset Code",
    "NUIMS Quantity",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el?.stock?.image || noImage}
          className="rounded-full w-12 h-12"
          alt={el?.stock?.asset_name}
        />
      </span>,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.request_id,
      el?.stock?.asset_code,
      el?.nuims_quantity,
    ];
  };

  const toggleRowFunction = (_, val) => {
    const itemId = val?.id;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems?.includes(itemId)) {
        return prevSelectedItems?.filter((selectedId) => selectedId !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  useEffect(() => {
    if (isSuccess || updated) {
      navigate("/lots");
    }
  }, [isSuccess, updated, navigate]);

  return (
    <BaseDashboardNavigation title={"Create Lot"}>
      <div className="p-6 h-auto rounded-[6px] relative  flex flex-col pt-6">
        <div className="flex flex-col md:flex-row md:space-x-2">
          <div className="flex-1 mb-2">
            <h3 className="text-[24px] font-bold">{"Create Lot"}</h3>
            <div className="w-full flex items-center justify-between my-3">
              <div className="flex items-center"></div>
              <GoBackButton />
            </div>
            <div className="bg-white p-4 rounded-md border border-gray-200">
              <div className="my-4 ">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Image
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-2"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="w-32 h-32 object-cover"
                    />
                  </div>
                )}
              </div>
              <InputWithFullBoarder
                label={"Lot Name"}
                placeholder={"Enter Lot Name"}
                type={"text"}
                value={lotName}
                wrapperClassName={"mt-5"}
                onChange={(e) => setLotName(e.target.value)}
                required
              />
              <TabsManagement
                currentView={currentView}
                setCurrentView={setCurrentView}
                options={["Select By Items", "Select By Batch"]}
              />
              {currentView === 0 ? (
                <div className="w-full flex flex-col items-start gap-y-3 ">
                  {/* {entries.map((el, i) => (
                <div
                  key={i}
                  className="flex flex-col md:flex-row  items-center w-full gap-5"
                >
                  <div className="mt-[10px] w-full">
                    <SearchableSelect
                      // label="Items"
                      selectOptions={itemOptions}
                      value={el.selectedItems}
                      onChange={(selected) => {
                        updateEntry({
                          index: i,
                          selectedItem: selected.label,
                          id: selected.value,
                        });
                      }}
                      placeholder="Search and select items"
                      required
                      isMulti={true}
                    />
                  </div>
                  <div className="flex items-center gap-5">
                    <Dropdown
                      value={el.selectedBatch}
                      onChange={(e) =>
                        updateEntry({ index: i, selectedBatch: e.target.value })
                      }
                      disabled={!el.selectedItem}
                      options={
                        itemsData.find((item) => item.id === el.id)?.batches
                      }
                      className={"w-full relative"}
                      placeholder={el?.selectedItem}
                    />
                    <button
                      onClick={() => removeEntry(el.id)}
                      className="p-2 text-red-600 hover:text-red-800"
                      aria-label="Remove entry"
                    >
                      <BiTrash size={20} />
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex justify-end space-x-2 mt-4 w-full">
                <CustomButton
                  buttonText={"Add Item"}
                  onClick={addNewEntry}
                  isLoading={false}
                />
              </div> */}
                  <SearchField
                    placeholder={"Search Asset"}
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                  />{" "}
                  <TablesComponent
                    isLoading={isLoading || fetchingItems}
                    data={data?.data?.results}
                    getFormattedValue={getFormattedValue}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    toggleRowFunction={(index, val) =>
                      toggleRowFunction(index, val)
                    }
                    toggleSelectAllFunction={() => {}}
                    headers={headers}
                    buttonFunction={(val) => {
                      console.log(val);
                      navigate(`/disposal/${val?.id}`);
                    }}
                    hideActionButton
                  />
                </div>
              ) : (
                <div className="w-full flex flex-col items-start gap-y-3">
                  <div className="w-full flex justify-between items-center">
                    <SearchField
                      placeholder={"Search by Request ID"}
                      onChange={(e) => setRequestId(e.target.value)}
                      value={requestId}
                    />
                    <div className="flex items-center gap-4">
                      {isBatchSelected && (
                        <span className="text-sm text-red-400">
                          Request ID '{requestId}' selected
                        </span>
                      )}
                      <ButtonWithIcon
                        buttonText={"Select All Items"}
                        className="bg-transparent text-brandGreen border border-brandGreen"
                        textColor={"#358619"}
                        radius={"md"}
                        onClick={handleBatchSelection}
                      />
                    </div>
                  </div>

                  {requestId && (
                    <TablesComponent
                      isLoading={isLoading}
                      data={data?.data?.results}
                      getFormattedValue={getFormattedValue}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                      toggleRowFunction={(index, val) =>
                        toggleRowFunction(index, val)
                      }
                      toggleSelectAllFunction={() => {}}
                      headers={headers}
                      buttonFunction={(val) => {
                        console.log(val);
                        navigate(`/disposal/${val?.id}`);
                      }}
                      hideActionButton
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
        <div className="flex justify-end my-2">
          <CustomButton
            buttonText={details ? "Update Lot" : "Create Lot"}
            onClick={handleCreateLot}
            isLoading={isLoading}
            className={"w-full max-w-max"}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default CreateLot;
