import React, { useState } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
// import { BiCopy } from "react-icons/bi";
import CustomButton from "../../../generalComponents/Button";

const ShareWarRoomModal = ({ link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  return (
    <ModalManagement id={`share_warroom`}>
      <div className="bg-white rounded-lg p-6 w-[480px]">
        <h2 className="text-xl font-semibold mb-4">Share War Room Link</h2>
        <div className="flex gap-2">
          <input
            type="text"
            value={link}
            readOnly
            className="w-full p-2 border rounded-md bg-gray-50"
          />
          <CustomButton
            onClick={handleCopy}
            className="flex items-center justify-center gap-2 bg-brandGreen text-white px-4 py-2 rounded-md hover:bg-opacity-90"
            buttonText={copied ? "Copied!" : "Copy"}
          />
          {/* <BiCopy size={16} /> */}
        </div>
        <div className="mt-6 flex justify-end">
          <CustomButton
            buttonText={`Close`}
            textColor={"text-red-500"}
            buttonColor={"transparent"}
            onClick={() => document.getElementById("share_warroom").close()}
            className={`w-full border border-red-500 text-red-500`}
          />
        </div>
      </div>
    </ModalManagement>
  );
};

export default ShareWarRoomModal;
