import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetStockReportManager = (id) => {
  return useQuery(
    ["report", id],
    async () => {
      try {
        const response = await AxiosWithToken.get(
          `/clients/${id}/stock/report/download/`,
          { responseType: "blob" }
        );
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.message}`);
      }
    },
    {
      enabled: false, // Don't fetch automatically
      retry: false,
    }
  );
};

export default useGetStockReportManager;
