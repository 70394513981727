import React, { useState } from "react";
import TablesComponent from "../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../generalComponents/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import useDebounce from "../../../utils/UseDebounce";
import { noImage } from "../../../assets/images";
import SearchField from "../../../generalComponents/SearchField";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import StatusButton from "../../../generalComponents/StatusButton";
import Dropdown from "../../../generalComponents/Dropdown";
import { hasPermissions } from "../../../constants/permissions";
import truncateWithTooltip from "../../../utils/truncateText";
import useGetDisposalsManager from "../../disposal/disposalUploads/controllers/getDisposalsController";

const AuctionItemsByRequestId = () => {
  const { request_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetDisposalsManager({
    request_id: request_id,
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Image",
    "Asset Name",
    "Asset Code",
    "NUIMS Quantity",
    "Approval Status",
  ];

  const getFormattedValue = (el, index) => {
    return [
      <span className="w-14 rounded-full">
        <img
          src={el?.stock?.image || noImage}
          className="rounded-full w-12 h-12"
          alt={el?.stock?.asset_name}
        />
      </span>,
      truncateWithTooltip(el?.stock?.asset_name),
      el?.stock?.asset_code,
      <span>{el?.nuims_quantity}</span>,
      <StatusButton status={el?.status} />,
    ];
  };

  return (
    <div>
      <div className="w-full flex flex-col gap-5"></div>
      <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />{" "}
          </div>

          <div className="flex space-x-[10px] mt-3 md:mt-0">
            {selectedRows.length > 0 && (
              <div
                className={`${
                  hasPermissions([
                    "approve_level_one_stock_disposal_item",
                    "approve_level_two_stock_disposal_item",
                    "approve_level_three_stock_disposal_item",
                    "reject_stock_disposal_item",
                  ])
                    ? "flex items-center space-x-[10px] mt-1 md:mt-0"
                    : "hidden"
                } `}
              >
                {" "}
                <Dropdown
                  placeholder={"Select Action"}
                  // options={dropdownOptions}
                  // onChange={handleDropdownChange}
                  buttonClassName="p-2 hover:bg-gray-100 rounded-md flex items-center space-x-2"
                />
              </div>
            )}
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              toggleRowFunction={() => {}}
              toggleSelectAllFunction={() => {}}
              headers={headers}
              buttonFunction={(val) => {
                console.log(val);
                navigate(`/disposal/${val?.id}`);
              }}
              hideActionButton
              showCheckBox={false}
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AuctionItemsByRequestId;
