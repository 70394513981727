import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModalManagement from "../../../generalComponents/ModalManagement";
import CustomButton from "../../../generalComponents/Button";
import ReceiptUploadModal from "./ReceiptUploadModal";
import useGetUserDetailsManager from "../../settings/controllers/get_UserDetails_controller";
import useGetRemitaTransaction from "../../../constants/remita/getRemitaTransactionController";
import { PostRemitaTransaction } from "../../../constants/remita/postRemitaTransactionController";
import { formatNumber } from "../../../utils/formatNumber";

const AuctionFeeModal = ({
  eventName,
  entryFee,
  eventId,
  existingRRR,
  trxId,
}) => {
  const { data: userData } = useGetUserDetailsManager();
  const [isLoadingScript, setIsLoadingScript] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const { isLoading: initLoading, refetch: initiateRemita } =
    useGetRemitaTransaction({
      id: eventId,
    });

  const {
    verifyRemita,
    isLoading: verifyLoading,
    isSuccess,
  } = PostRemitaTransaction({
    id: eventId,
  });

  useEffect(() => {
    const loadRemitaScript = () => {
      // Remove existing script if any
      const existingScript = document.querySelector(
        'script[src*="remita-pay-inline"]'
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
        setIsScriptLoaded(false);
      }

      const script = document.createElement("script");
      script.src =
        "https://remitademo.net/payment/v1/remita-pay-inline.bundle.js";
      script.async = true;

      script.onload = () => {
        // Add a small delay to ensure RmPaymentEngine is fully initialized
        setTimeout(() => {
          if (
            window.RmPaymentEngine &&
            typeof window.RmPaymentEngine.init === "function"
          ) {
            console.log("RmPaymentEngine initialized successfully");
            setIsScriptLoaded(true);
          } else {
            console.error("RmPaymentEngine not properly initialized");
            toast.error(
              "Payment system initialization failed. Please refresh the page."
            );
            setIsScriptLoaded(false);
          }
        }, 1000);
      };

      script.onerror = () => {
        console.error("Failed to load Remita script");
        toast.error("Failed to load payment system. Please try again.");
        setIsScriptLoaded(false);
      };

      document.body.appendChild(script);
      return script;
    };

    const script = loadRemitaScript();
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const initializePayment = async () => {
    try {
      const response = await initiateRemita();
      console.log("Payment initialization response:", response);

      if (
        !response?.data?.data?.gateway_reference ||
        !response?.data?.data?.reference
      ) {
        console.error("Invalid payment initialization response:", response);
        throw new Error("Invalid payment response from server");
      }

      return {
        rrr: response.data.data.gateway_reference,
        transactionId: response.data.data.reference,
      };
    } catch (error) {
      console.error("Payment initialization error:", error);
      toast.error(error.message || "Failed to initialize payment");
      throw error;
    }
  };

  const handlePaymentVerification = async (rrr) => {
    try {
      await verifyRemita({
        remita_reference: rrr,
        is_paid: true,
      });

      toast.success("Payment verified successfully");
      // Use window.location.reload() instead of window.reload
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      console.error("Payment verification error:", error);
      toast.error("Payment verification failed. Please contact support.");
    }
  };

  const handleRemitaPayment = async () => {
    if (
      !window.RmPaymentEngine ||
      typeof window.RmPaymentEngine.init !== "function"
    ) {
      console.error("RmPaymentEngine not available or init method missing");
      toast.error("Payment system is not ready. Please refresh the page.");
      return;
    }

    setIsLoadingScript(true);

    try {
      let paymentDetails;

      if (existingRRR && trxId) {
        paymentDetails = {
          rrr: existingRRR,
          transactionId: trxId,
        };
      } else {
        paymentDetails = await initializePayment();
      }

      console.log("Payment details:", paymentDetails);

      const paymentConfig = {
        key: process.env.REACT_APP_REMITA_PUBLIC_KEY,
        customerId: userData?.data?.user?.email,
        transactionId: paymentDetails.transactionId,
        firstName: userData?.data?.user?.first_name,
        lastName: userData?.data?.user?.last_name,
        email: userData?.data?.user?.email,
        amount: entryFee,
        narration: `Auction event fee payment for ${eventName}`,
        currency: "NGN",
        environment: "TEST",
        onSuccess: async (response) => {
          console.log("Payment success response:", response);
          await handlePaymentVerification(paymentDetails.rrr);
        },
        onError: (error) => {
          console.error("Payment error:", error);
          toast.error(error?.message || "Payment failed");
        },
        onClose: () => {
          console.log("Payment modal closed");
          document.getElementById("auction_fee_modal").showModal();
        },
        extendedData: {
          customFields: [
            {
              name: "rrr",
              value: paymentDetails.rrr,
            },
          ],
        },
      };

      console.log("Initializing payment with config:", paymentConfig);
      const paymentHandler = window.RmPaymentEngine.init(paymentConfig);

      if (!paymentHandler) {
        console.error("Payment handler initialization failed");
        throw new Error("Failed to initialize payment handler");
      }

      document.getElementById("auction_fee_modal").close();
      paymentHandler.openIframe();
    } catch (error) {
      console.error("Payment handler error:", error);
      toast.error(error.message || "Payment initialization failed");
    } finally {
      setIsLoadingScript(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("auction_fee_modal").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id="auction_fee_modal">
        <div className="md:w-[400px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <p className="text-18px font-semibold py-4 text-center">
            How would you like to pay for the ₦{formatNumber(entryFee)} auction
            fee for {eventName} event?
          </p>
          <div className="flex justify-center space-x-2 mt-4 w-full">
            <CustomButton
              buttonText="Bank Transfer"
              textColor="#368618"
              className="bg-white border border-brandGreen text-[#368618]"
              onClick={() => {
                document.getElementById("receipt_upload").showModal();
                document.getElementById("auction_fee_modal").close();
              }}
            />
            <CustomButton
              buttonText={
                initLoading || verifyLoading || isLoadingScript
                  ? "Processing..."
                  : "Pay Via Remita"
              }
              onClick={handleRemitaPayment}
              disabled={
                initLoading ||
                verifyLoading ||
                isLoadingScript ||
                !isScriptLoaded
              }
            />
          </div>
        </div>
      </ModalManagement>
      <ReceiptUploadModal entryFee={entryFee} />
    </div>
  );
};

export default AuctionFeeModal;
