import React, { useEffect, useState } from "react";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import useDebounce from "../../../utils/UseDebounce";
import SearchField from "../../../generalComponents/SearchField";
import AdminDeleteConfirmationModal from "../../../generalComponents/AdminDeleteConfirmationModal";
import { DeleteAuctionManager } from "../controllers/deleteAuctionController";
import useGetAuctionManager from "../controllers/getAuctionController";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatNumber } from "../../../utils/formatNumber";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { noImage } from "../../../assets/images";
import { hasPermissions } from "../../../constants/permissions";

const AuctionPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);
  const navigate = useNavigate();

  const { data, isLoading } = useGetAuctionManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Image",
    "Event Name",
    "Event Type",
    "Entry Fee",
    "Start Date",
    "End Date",
    "Action",
  ];

  const options = [
    // { label: "View Auction", permissions: ["view_auction"] },
    { label: "Edit Auction", permissions: ["change_auction"] },
    { label: "Delete Auction", permissions: ["delete_auction"] },
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      <span>
        <img src={el?.event_image_url || noImage} alt="Item" className="w-12" />
      </span>,
      el?.event_name,
      <span>{capitalizeFirstLetter(el?.event_type)}</span>,
      <span>{formatNumber(el?.entry_fee)}</span>,
      <span>{formatDateAndTime(el?.start_datetime)}</span>,
      <span>{formatDateAndTime(el?.end_datetime)}</span>,
    ];
  };

  const {
    deleteAuction,
    isLoading: deleting,
    isSuccess: deleted,
  } = DeleteAuctionManager({
    id: selected,
  });

  const handleDelete = async () => {
    await deleteAuction();
  };

  useEffect(() => {
    if (deleted) {
      document.getElementById("admin_delete").close();
    }
  }, [deleted]);

  return (
    <BaseDashboardNavigation>
      <div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
          <div className="flex items-center mb-2 md:mb-0">
            <h3 className="text-[24px] font-bold">Auction Management</h3>
          </div>
        </div>
        <div className="mt-2 md:mt-6 flex flex-col w-full gap-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="">
              <SearchField
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />{" "}
            </div>
            <div
              className={`${
                hasPermissions(["add_auction"])
                  ? "flex space-x-[10px] mt-3 md:mt-0"
                  : "hidden"
              } `}
            >
              <ButtonWithIcon
                buttonText={"Create Auction"}
                radius={"md"}
                icon={BiPlus}
                onClick={() => {
                  navigate("/auction/create-auction");
                }}
              />
            </div>
          </div>
          <div className="h-[67vh] w-full relative">
            {
              <TablesComponent
                isLoading={isLoading}
                data={data?.data?.results}
                getFormattedValue={getFormattedValue}
                headers={headers}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                toggleRowFunction={() => {}}
                toggleSelectAllFunction={() => {}}
                options={options}
                popUpFunction={(option, index, val) => {
                  console.log(option, index, val);
                  setSelected(val);
                  // if (index === 0) {
                  //   navigate(`/auction/${val?.id}`);
                  // }
                  if (index === 0) {
                    navigate(`/auction/edit-auction/${val?.id}`);
                  }
                  if (index === 1) {
                    setSelected(val.id);
                    document.getElementById("admin_delete").showModal();
                  }
                }}
                // Close popup function
              />
            }
          </div>
          <div className="flex items-center justify-between mt-4">
            <p className="text-14px text-brandBlack">
              {" "}
              {startItem} - {endItem} of {data?.data?.pagination?.count} Events
            </p>
            <PaginationRounded
              defaultPage={data?.data?.pagination?.page}
              count={data?.data?.pagination?.total_pages}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        </div>
      </div>
      <AdminDeleteConfirmationModal
        body={`Are you sure you want to delete this auction?`}
        title={"Delete Auction"}
        buttonText={"Yes, Delete"}
        isLoading={deleting}
        onClick={handleDelete}
      />
    </BaseDashboardNavigation>
  );
};

export default AuctionPage;
