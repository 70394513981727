import React, { useEffect, useState } from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import useGetCartItemsManager from "../controllers/getCartItemsController";
import { noImage } from "../../../assets/images";
import { BiCart, BiTrash } from "react-icons/bi";
import { formatNumber } from "../../../utils/formatNumber";
import CartFeeModal from "../components/CartFeeModal";
import CustomButton from "../../../generalComponents/Button";

const Cart = () => {
  const { data: cart } = useGetCartItemsManager({
    is_winner: true,
    isPaid: false,
  });

  const [cartItems, setCartItems] = useState(cart?.data?.results || []);
  // const cartItems = cart?.data?.results;

  useEffect(() => {
    if (cart?.data?.results) {
      setCartItems(cart.data.results);
    }
  }, [cart]);

  const handleRemoveItem = (itemId) => {
    setCartItems((prev) => prev.filter((item) => item.id !== itemId));
  };

  const subtotal = cartItems.reduce((sum, item) => {
    const bidAmount = parseFloat(item?.highest_bid_amount || "0");
    return sum + bidAmount;
  }, 0);

  const total = subtotal;

  // useEffect(() => {
  //   cartItems.forEach((item, index) => {
  //     console.log(
  //       `Item ${index + 1} amount:`,
  //       parseFloat(item.highest_bid_amount)
  //     );
  //   });
  // }, [cartItems]); // In this case total equals subtotal since there are no additional charges

  return (
    <HeaderFooter>
      <div className="max-w-[1240px] w-full flex flex-col my-8 md:mx-auto mx-4 relative">
        <h3 className="text-28px font-semibold md:mx-0 mx-4">Your Cart</h3>
        <div className="grid md:grid-cols-3 gap-6">
          {/* Cart Items Section */}
          <div className="md:col-span-2">
            <div className="bg-white rounded-md shadow-md">
              <div className="flex items-center justify-between p-4 border-b">
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-600">
                    {cartItems?.length} Lot Items
                  </span>
                </div>
              </div>
              {cartItems.length > 0 ? (
                <div className="p-4">
                  {cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center justify-between py-4 border-b last:border-0"
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={item.image_url || noImage}
                          alt={item.name}
                          className="w-20 h-20 object-cover rounded"
                        />
                        <div>
                          <h2 className="font-medium text-[20px]">
                            {item.name}
                          </h2>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 font-medium text-[20px]">
                        <span className="text-gray-600">
                          ₦{formatNumber(item.highest_bid_amount)}
                        </span>
                        <button
                          onClick={() => handleRemoveItem(item.id)}
                          className="text-red-500 hover:text-red-700 p-2"
                        >
                          <BiTrash
                            onClick={() => handleRemoveItem(item.id)}
                            size={20}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center p-8">
                  <BiCart className="w-20 h-28 text-brandGreen" />
                  <p className="text-gray-500 text-lg text-center">
                    Your cart is empty
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Summary Section */}
          <div className="bg-white rounded-md shadow-md p-4">
            <h2 className="text-lg font-semibold mb-4">Summary</h2>
            <div className="space-y-3">
              <div className="flex justify-between border-t py-3">
                <span>Subtotal</span>
                <span>₦{formatNumber(subtotal)}</span>
              </div>
              <div className="flex justify-between font-bold pt-3 border-t">
                <span>Total</span>
                <span>₦{formatNumber(total)}</span>
              </div>
              <CustomButton
                buttonText={"Proceed to Checkout"}
                onClick={() => {
                  document.getElementById("cart_fee_modal").showModal();
                }}
                className="w-full bg-brandGreen text-white py-4 rounded mt-8"
                disabled={cartItems?.length === 0}
              >
                Proceed to Checkout
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <CartFeeModal
        totalAmount={total}
        cartItems={cartItems}
        eventId={cartItems[0]?.event_id} // Assuming all items are from the same event
        eventName={cartItems[0]?.event_name} // Assuming all items are from the same event
      />{" "}
    </HeaderFooter>
  );
};

export default Cart;
