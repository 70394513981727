import React, { useState } from "react";
import TablesComponent from "../../../generalComponents/TablesComponent";
import PaginationRounded from "../../../generalComponents/Pagination";
import { noImage } from "../../../assets/images";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import { formatDateAndTime } from "../../../utils/formatDateAndTime";
import { formatNumber } from "../../../utils/formatNumber";
import VendorLayout from "../../../generalComponents/VendorLayout";
import useGetWonLotsManager from "../controllers/getWonLotsController";
import StatusButton from "../../../generalComponents/StatusButton";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { FaCertificate, FaDownload } from "react-icons/fa";
import useGetUserDetailsManager from "../../settings/controllers/get_UserDetails_controller";
import useLotExportManager from "../controllers/getLotExportController";
import certificate from "../../../assets/auction-certificate.pdf";
import { generateCertificate } from "../../../utils/generateCertificate";
import { BiMoney } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const WonLots = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lotId, setLotId] = useState("");
  const navigate = useNavigate();

  const { data, isLoading } = useGetWonLotsManager({
    page: currentPage,
    is_winner: true,
  });

  const { isLoading: lotsLoading, refetch: fetchLots } =
    useLotExportManager(lotId);

  const { data: userDetails } = useGetUserDetailsManager();

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "Lot Name",
    "Amount",
    "End Date",
    "Payment Status",
    "Action",
  ];

  const getFormattedValue = (data, index) => {
    return [
      <span className="flex gap-x-2 items-center">
        <img
          src={data?.image_url || noImage}
          alt="Logo"
          className="w-10 h-10 rounded-full"
        />
        {data?.name}
      </span>,
      <span>{formatNumber(data?.highest_bid_amount)}</span>,
      <span> {formatDateAndTime(data?.end_datetime)}</span>,
      <StatusButton
        status={
          data?.transaction_status === "PROCESSING"
            ? "Awaiting Confirmation"
            : data?.transaction_status
        }
      />,
      <span>
        {data?.transaction_status === "CONFIRMED" ? (
          <div>
            <ButtonWithIcon
              buttonText={"Download Certificate"}
              icon={FaCertificate}
              buttonColor={"transparent"}
              textColor={"text-brandGreen"}
              className={
                "text-[8px] border border-brandGreen text-brandGreen my-2"
              }
              onClick={() =>
                generateCertificatePDF({
                  company_name: data.company_name,
                  lot_name: data.name,
                  event_name: data.event_name,
                  reference: data.reference,
                })
              }
            />
            <ButtonWithIcon
              buttonText="Download Won Items"
              icon={FaDownload}
              buttonColor="transparent"
              textColor="text-brandGreen"
              onClick={() => handleDownload(data.id)}
              // disabled={lotsLoading}
              // isLoading={lotsLoading}
              className="text-[8px] border border-brandGreen text-brandGreen my-2"
            />
          </div>
        ) : data?.transaction_status === "PENDING" ? (
          <div>
            <ButtonWithIcon
              buttonText="Make Payment"
              icon={BiMoney}
              buttonColor={"transparent"}
              textColor={"text-brandGreen"}
              onClick={() => {
                navigate(`/cart`);
              }}
              disabled={lotsLoading}
              className={
                "text-[8px] border border-brandGreen text-brandGreen my-2"
              }
            />
          </div>
        ) : null}
      </span>,
    ];
  };

  const companyName = userDetails?.data?.client?.name;
  const staffName =
    userDetails?.data?.user?.first_name +
    " " +
    userDetails?.data?.user?.last_name;

  const generateCertificatePDF = async (data) => {
    try {
      const pdfBytes = await generateCertificate(
        certificate,
        data,
        companyName,
        staffName
      );
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Auction Certificate for ${companyName}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating certificate:", error);
    }
  };

  const handleDownload = async (id) => {
    setLotId(id);
    const result = await fetchLots();
    if (result?.data) {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `Auction - Won Items.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <VendorLayout>
      <div className="max-w-[1240px] w-full flex flex-col text-blackColor mx-auto ">
        <div className="w-full justify-between items-center flex  py-6 mb-4">
          <h3 className="text-28px font-semibold md:mx-0 mx-4">Won Items </h3>{" "}
        </div>
        <div className="flex flex-col w-full gap-7">
          <div className="h-[67vh] w-full relative">
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              toggleRowFunction={() => {}}
              toggleSelectAllFunction={() => {}}
              showCheckBox={false}
              hideActionButton
            />
          </div>
          <div className="flex items-center justify-between mt-4">
            <p className="text-14px text-brandBlack">
              {startItem} - {endItem} of {data?.data?.pagination?.count} users
            </p>
            <PaginationRounded
              defaultPage={data?.data?.pagination?.page}
              count={data?.data?.pagination?.total_pages}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </VendorLayout>
  );
};

export default WonLots;
