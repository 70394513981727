import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetStockManager = ({
  allocation,
  page,
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  clientId = "",
}) => {
  return useQuery(
    ["stocks", page, allocation, clientId, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/stock/allocations/?allocation=${allocation}&client=${clientId}&page=${page}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetStockManager;
