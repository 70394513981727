import React, { useState } from "react";
import PaginationRounded from "../../../generalComponents/Pagination";
import TablesComponent from "../../../generalComponents/TablesComponent";
import { useNavigate } from "react-router-dom";
import { calculatePaginationRange } from "../../../utils/calculatePaginationRange";
import SearchField from "../../../generalComponents/SearchField";
import useDebounce from "../../../utils/UseDebounce";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import StatusButton from "../../../generalComponents/StatusButton";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import useGetAuctionItemsManager from "../controllers/getAuctionItemsController";

const AuctionItems = ({ id }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dateBefore, setDateBefore] = useState("");
  const [dateAfter, setDateAfter] = useState("");
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(`&search=${searchValue}`, 1000);

  const { data, isLoading } = useGetAuctionItemsManager({
    enabled: true,
    searchQuery: debouncedSearchValue,
    page: currentPage,
    date_after: dateAfter,
    date_before: dateBefore,
    clientId: id,
    status: "LEVEL_THREE_APPROVED",
  });

  const { startItem, endItem } = calculatePaginationRange(
    data?.data?.pagination
  );

  const headers = [
    "S/N",
    "Request ID",
    "Operator",
    "Upload Date",
    "Approval Status",
    "Action",
  ];

  const getFormattedValue = (el, index) => {
    return [
      index + 1,
      el?.request_id,
      el?.client,
      <span>
        {new Date(el?.created_datetime).toLocaleString("en-GB", {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </span>,
      <StatusButton status={el?.approval_status} />,
    ];
  };

  return (
    <BaseDashboardNavigation>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full">
        <div className="flex items-center mb-2 md:mb-0">
          <h3 className="text-[24px] font-bold">Auction Items</h3>
        </div>
      </div>
      <div className="mt-2 md:mt-2 flex flex-col w-full gap-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="">
            <SearchField
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          <div className="flex items-center space-x-4 justify-center align-middle">
            <span className="text-[14px] ">Select Date Range:</span>
            <InputWithFullBoarder
              className="flex-1"
              type="date"
              value={dateAfter}
              onChange={(e) => {
                setDateAfter(e.target.value);
              }}
            />

            <span className="whitespace-nowrap">to</span>
            <InputWithFullBoarder
              className="flex-1"
              type="date"
              value={dateBefore}
              onChange={(e) => {
                setDateBefore(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="h-[67vh] w-full relative">
          {
            <TablesComponent
              isLoading={isLoading}
              data={data?.data?.results}
              getFormattedValue={getFormattedValue}
              headers={headers}
              buttonFunction={(val) => {
                console.log(val);
                navigate(
                  `/auction-items/${encodeURIComponent(val?.request_id)}`,
                  {
                    state: { itemId: val?.id, approvalStatus: val?.status },
                  }
                );
              }}
              showCheckBox={false}
            />
          }
        </div>
        <div className="flex items-center justify-between mt-4">
          <p className="text-14px text-brandBlack">
            {" "}
            {startItem} - {endItem} of {data?.data?.pagination?.count} Items
          </p>
          <PaginationRounded
            defaultPage={data?.data?.pagination?.page}
            count={data?.data?.pagination?.total_pages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </BaseDashboardNavigation>
  );
};

export default AuctionItems;
