import React, { useEffect, useState } from "react";
import BaseDashboardNavigation from "../../../../generalComponents/BaseDashboardNavigation";

import { useLocation, useParams } from "react-router-dom";
import { getLastPathSegment } from "../../../../utils/getLastPath";
import Breadcrumb from "../../../../generalComponents/BreadCrumb";
import CustomButton from "../../../../generalComponents/Button";
import useGetStockItemByIdManager from "../controllers/getStockItemByIdController";
import { noImage } from "../../../../assets/images";
import AssetDetailsSection from "./AssetDetailsSection";
import Tabs from "../../../../generalComponents/Tabs";
import ModalManagement from "../../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import { TransferStockManager } from "../controllers/transferStockController";
import StockRequests from "../../stockRequests/view/StockRequests";
import StockActivityLogs from "./StockActivityLogs";
import StockItemModal from "./StockItemModal";
import UtilizationModal from "./UtilizationModal";
import UtilizationLogs from "./UtilizationLogs";

const StockItemDetailsComponent = () => {
  const { id } = useParams();

  const location = useLocation();
  const lastSegment = getLastPathSegment(location.pathname);
  const [surplusQuantity, setSurplusQuantity] = useState(0);
  const [selectedStock, setSelectedStock] = useState("");

  const { data: itemDetails } = useGetStockItemByIdManager(id);

  const item = itemDetails?.data;

  const projectItems = itemDetails?.data?.allocation === "PROJECT";

  const items = [
    { name: "Stocks", path: "/inventory/" },
    {
      name: item?.asset_name,
      path: `/inventory/${lastSegment}`,
    },
  ];

  const assetDetailsData = [
    { label: "Asset Code", value: item?.asset_code || "N/A" },
    { label: "Material", value: item?.material || "N/A" },
    { label: "Quantity", value: item?.quantity || "N/A" },
    { label: "Unit", value: item?.unit || "N/A" },
    { label: "Unit Cost Naira", value: item?.unit_cost_naira || "N/A" },
    { label: "Unit Cost USD", value: item?.unit_cost_dollar || "N/A" },
    {
      label: "Project Name",
      value: projectItems
        ? itemDetails?.data?.project?.name
        : item?.project || "N/A",
    },
    { label: "Manufacturer", value: item?.manufacturer || "N/A" },
    { label: "Warehouse", value: item?.warehouse || "N/A" },
    { label: "Storage Location", value: item?.storage_location || "N/A" },
    { label: "Row", value: item?.row || "N/A" },
    { label: "Slot", value: item?.slot || "N/A" },
    { label: "Dimension", value: item?.dimension || "N/A" },
    { label: "Component", value: item?.component || "N/A" },
    { label: "Classification", value: item?.classification || "N/A" },
    { label: "Connection Type", value: item?.connection_type || "N/A" },
    { label: "Capacity", value: item?.capacity || "N/A" },
    { label: "ERP Number", value: item?.erp_number || "N/A" },
    { label: "Serial Number", value: item?.serial_number || "N/A" },
    { label: "Model Number", value: item?.model_number || "N/A" },
    { label: "Bin Number", value: item?.bin_number || "N/A" },
    { label: "Bactch Number", value: item?.batch_number || "N/A" },
  ];

  const tabsData = [
    {
      label: "Asset Details",
      component: <AssetDetailsSection data={assetDetailsData} />,
    },
    {
      label: "Activity Log",
      component: <StockActivityLogs stock_id={item?.stock_id} />,
    },
    {
      label: "Utilization",
      component: <UtilizationLogs stock_id={item?.stock_id} />,
    },
    {
      label: "Requests",
      component: <StockRequests stock_id={item?.stock_id} />,
    },
  ];

  const { transferStock, isSuccess } = TransferStockManager(id);

  const handleDeclareSurplus = () => {
    const payload = {
      allocation: "SURPLUS",
      quantity: surplusQuantity,
    };

    transferStock(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("declare_surplus").close();
      setSurplusQuantity(0);
    }
  }, [isSuccess]);

  return (
    <BaseDashboardNavigation>
      <div className="md:mt-4 flex flex-col md:flex-row mt-2 justify-between gap-y-5">
        <div>
          <Breadcrumb items={items} />
        </div>
        <div className="flex gap-x-2">
          <CustomButton
            buttonText={"Declare Surplus"}
            onClick={() => {
              document.getElementById("declare_surplus").showModal();
            }}
            className={"bg-white text-brandGreen border border-brandGreen"}
            textColor={"358619"}
          />
          <CustomButton
            buttonText={"Edit Item"}
            onClick={() => {
              setSelectedStock(item);
              document.getElementById("create_stock").showModal();
            }}
          />
          {projectItems && (
            <CustomButton
              buttonText={"Record Utilization"}
              onClick={() => {
                document.getElementById("utilize_stock").showModal();
              }}
            />
          )}
        </div>
      </div>
      <div className="">
        <div className="flex md:flex-row flex-col relative items-center mb-4 gap-5 w-full h-[450px] mt-5">
          {/* Left Card with Image */}
          <div className="flex flex-[0.5] items-center bg-white p-2 rounded-lg md:w-full h-full">
            <img
              src={item?.image || noImage}
              className=" overflow-hidden rounded-lg shadow-md w-full h-full object-cover"
              alt="Item"
            />
          </div>

          {/* Right Card */}
          <div className="flex flex-[0.5] flex-col justify-center  gap-4 h-full w-full  bg-white rounded-lg border border-gray-200 p-6">
            <div className="flex flex-col gap-2 py-2 items-start border-b border-gray-200">
              <h1 className="text-20px md:text-[28px] font-semibold my-2 leading-[20px]">
                {item?.asset_name}
              </h1>
              <p className="text-gray-500 text-sm">{item?.description}</p>
            </div>
            <div className="w-full flex flex-col gap-3 items-start border-b border-gray-200">
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Operator</p>
                <h2 className="text-16px md:text-[24px] font-semibold ">
                  {item?.client?.name}
                </h2>
              </div>
              <div className="flex flex-col my-1.5">
                <p className="text-gray-500 text-sm">Storage Location</p>
                <h2 className="text-16px md:text-[24px] font-semibold">
                  {item?.storage_location || "N/A"}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>

      <ModalManagement id={"declare_surplus"} title={"Declare Surplus"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={surplusQuantity}
                onChange={(e) => setSurplusQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("declare_surplus").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Declare Surplus"}
              onClick={handleDeclareSurplus}
            />
          </div>
        </div>
      </ModalManagement>
      <StockItemModal details={selectedStock} />
      <UtilizationModal stock_id={item?.id} />
    </BaseDashboardNavigation>
  );
};

export default StockItemDetailsComponent;
