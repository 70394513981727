import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const IconsWithText = ({
  icon,
  text,
  iconSize,
  editText,
  className,
  path,
  activeIcon,
}) => {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`py-3 px-3 hover:text-menuBlack hover:cursor-pointer  hover:rounded-md hover:bg-[#EDF6E6] duration-300 ${
        location.pathname.includes(path)
          ? `py-4 px-3  rounded-md bg-[#EDF6E6] w-full text-[#358619]`
          : `px-3`
      } flex space-x-2 justify-start text-[${iconSize}] ${className} items-center text-menuBlack group`}
    >
      {activeIcon ? (
        <img
          src={location.pathname === path || isHovered ? activeIcon : icon}
          alt="Icon"
        />
      ) : (
        icon
      )}

      <h4 className={` ${editText ?? "text-[14px]  font-[500]"} `}>{text}</h4>
    </div>
  );
};

export default IconsWithText;
