export const DEFAULT_CLIENT_PERMISSIONS = {
  OPERATOR: [
    "add_role",
    "change_role",
    "delete_role",
    "view_role",
    "add_staff",
    "change_staff",
    "delete_staff",
    "view_staff",
    "add_conversation",
    "change_conversation",
    "delete_conversation",
    "view_conversation",
    "add_message",
    "change_message",
    "delete_message",
    "view_message",
    "accept_inspection",
    "add_inspection",
    "change_inspection",
    "delete_inspection",
    "reject_inspection",
    "reschedule_inspection",
    "view_inspection",
    "view_inspectionactivitylog",
    "add_inspectionreport",
    "change_inspectionreport",
    "delete_inspectionreport",
    "view_inspectionreport",
    "add_stock",
    "change_stock",
    "delete_stock",
    "view_stock",
    "add_stockallocation",
    "change_stockallocation",
    "delete_stockallocation",
    "transfer_stockallocation",
    "view_stockallocation",
    "export_stockbatch",
    "view_stockbatch",
    "accept_stockborrow",
    "add_stockborrow",
    "change_stockborrow",
    "complete_stockborrow",
    "delete_stockborrow",
    "reject_stockborrow",
    "view_stockborrow",
    "add_stockdisposal",
    "change_stockdisposal",
    "declare_stockdisposal",
    "delete_stockdisposal",
    "view_stockdisposal",
    "add_stockdisposaldocument",
    "change_stockdisposaldocument",
    "delete_stockdisposaldocument",
    "view_stockdisposaldocument",
    "add_stockdisposalitem",
    "change_stockdisposalitem",
    "delete_stockdisposalitem",
    "view_stockdisposalitem",
    "accept_stockexchange",
    "add_stockexchange",
    "change_stockexchange",
    "complete_stockexchange",
    "delete_stockexchange",
    "reject_stockexchange",
    "view_stockexchange",
    "add_stockheader",
    "change_stockheader",
    "delete_stockheader",
    "view_stockheader",
    "view_stockmovementlog",
    "add_stockreturn",
    "approve_stockreturn",
    "change_stockreturn",
    "complete_stockreturn",
    "delete_stockreturn",
    "reject_stockreturn",
    "view_stockreturn",
    "accept_stocksale",
    "add_stocksale",
    "change_stocksale",
    "complete_stocksale",
    "delete_stocksale",
    "purchase_stocksale",
    "reject_stocksale",
    "view_stocksale",
    "view_stockuploadhistory",
    "add_stockutilization",
    "change_stockutilization",
    "delete_stockutilization",
    "view_stockutilization",
    "add_project",
    "change_project",
    "delete_project",
    "view_project",
    "add_thread",
    "change_thread",
    "delete_thread",
    "view_thread",
    "add_ticket",
    "change_ticket",
    "delete_ticket",
    "view_ticket",
    "add_transaction",
    "change_transaction",
    "view_transaction",
    "add_warehouse",
    "change_warehouse",
    "delete_warehouse",
    "view_warehouse",
  ],
  VENDOR: [
    "view_bid",
    "add_bid",
    "view_lotitem",
    "view_lot",
    "view_auction",
    "particpate_in_auction",
    "add_transaction",
    "view_transaction",
    "view_ticket",
    "add_ticket",
  ],
};
