import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LightGreenButtons from "../../../generalComponents/LightGreenButtons";
import { noImage } from "../../../assets/images";

const EventTiles = ({ id, eventName, eventImage, endDatetime, lotsCount }) => {
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const countdown = () => {
      const endDate = new Date(endDatetime);
      const now = new Date();
      const difference = endDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeRemaining(`${days}d : ${hours}h : ${minutes}m : ${seconds}s`);
      } else {
        setTimeRemaining("Event Ended");
      }
    };

    countdown(); // Run the countdown initially
    const intervalId = setInterval(countdown, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [endDatetime]);

  return (
    <div
      className="max-w-[346px] w-full h-[360px] bg-whiteColor rounded-[5px] relative shadow-md cursor-pointer"
      onClick={() => navigate(`/events/${id}`)}
    >
      <img
        src={eventImage || noImage}
        alt="Item"
        className="h-[255.38px] object-cover w-full "
      />
      <div className="px-5 py-4 flex flex-col justify-between h-[30%]">
        <div className="flex flex-col items-start">
          <h3 className="text-18px font-semibold">{eventName}</h3>
        </div>
        <div className="flex justify-between relative">
          <LightGreenButtons text={timeRemaining} />
          <LightGreenButtons text={`Lots: ${lotsCount}`} />
        </div>
      </div>
    </div>
  );
};

export default EventTiles;
