import { useQuery } from "react-query";
import AxiosWithToken from "../../../../constants/api_management/MyHttpHelperWithToken";

const useGetBidByLotId = ({ id, enabled, participant }) => {
  return useQuery(
    [id, "bid", participant],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/bids/?lot=${id}&is_participant_bid=${participant}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    },
    {
      enabled: !!id,
    }
  );
};

export default useGetBidByLotId;
