import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { logo } from "../../../assets/images";
import CustomButton from "../../../generalComponents/Button";
import LeftSideComponent from "../components/LeftSideComponent";
import ChangePasswordModal from "../components/ChangePasswordModal";
import OTPInput from "react-otp-input";
import useAdminVerificationManager from "../controller/adminVerificationController";
import { ToastContainer } from "react-toastify";

const OTPPage = () => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(300);

  const { postCaller, isSuccess, isLoading } = useAdminVerificationManager();

  const handleSubmission = async (e) => {
    e.preventDefault();
    const details = {
      email: email,
      totp: otp,
    };
    await postCaller(details);

    if (isSuccess) {
      setOtp("");
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <div className="flex flex-col md:flex-row sm:h-screen">
      <ToastContainer />
      <LeftSideComponent />

      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white p-8">
        <div className="w-full max-w-md">
          <img
            src={logo}
            alt="EMMS Logo"
            className="mb-[105px] mx-auto h-[62px] w-auto object-contain"
          />
          <h2 className="text-2xl md:text-3xl font-bold mb-2 mt-3 text-center">
            Enter OTP Code
          </h2>
          <p className="text-14px text-[#645D5D] mb-6 text-center">
            Enter the otp code sent to{" "}
            <span className="text-[#1B1818]">{email}</span> to proceed
          </p>

          <form onSubmit={handleSubmission}>
            <OTPInput
              containerStyle="w-full items-center justify-center mt-2 flex mb-10"
              inputStyle={{
                backgroundColor: "#F4F4F4",
                width: 40,
                height: 40,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: 1,
                borderColor: "#000000",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span className="mr-3"></span>}
              renderInput={(props) => <input {...props} />}
            />

            <CustomButton
              buttonText={"Continue"}
              isLoading={isLoading}
              radius={"md"}
              className="w-full"
              type="submit"
            />
          </form>
        </div>
        <div className="flex items-center justify-center mt-4 w-full max-w-md">
          {timeLeft === 0 ? (
            <a
              className="inline-block align-baseline text-14px font-semibold text-brandGreen underline"
              href="/login"
            >
              Resend OTP
            </a>
          ) : (
            <a
              className="inline-block align-baseline text-14px font-semibold text-brandGreen underline"
              href="/login"
            >
              Go Back to Login <br />
            </a>
          )}
        </div>
        {timeLeft > 0 && (
          <span className="text-14px font-semibold text-[#667185] ml-2 mt-4">
            {formatTime(timeLeft)}
          </span>
        )}
      </div>
      <ChangePasswordModal />
    </div>
  );
};

export default OTPPage;
