import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetAuctionManager = ({
  page = "",
  searchQuery = "",
  enabled = true,
  pageSize = "15",
  status = "",
  inEvent = "",
  isUpcoming = "",
  isClosed = "",
}) => {
  return useQuery(
    ["auction", page, status, inEvent, isUpcoming, isClosed, searchQuery],
    async () => {
      try {
        const [response] = [
          await AxiosWithToken.get(
            `/auctions?is_active=${status}&is_in_event=${inEvent}&is_upcoming=${isUpcoming}&is_closed=${isClosed}&page=${page}&size=${pageSize}${searchQuery}`
          ),
        ];
        //console.log(`i am checking this ${response.status}`);
        return response.data;
      } catch (error) {
        //console.log(error.response.data);
        throw new Error(`Sorry: ${error.response.data.message}`);
      }
    }
  );
};

export default useGetAuctionManager;
