import React from "react";

const ButtonWithIcon = ({
  buttonText,
  textColor,
  className,
  onClick,
  buttonColor,
  radius,
  isLoading,
  type,
  loader,
  disabled,
  icon: IconComponent,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${
        buttonColor ?? "bg-brandGreen"
      } flex items-center text-12px md:text-14px py-[8px] md:py-[8px] px-[16px] md:px-[25px] font-regular hover:shadow-xl hover:scale-y-105 duration-300 h-[36px] ${
        !textColor ? "text-whiteColor" : textColor
      } rounded-${radius ?? "md"} ${className} ${
        disabled
          ? "bg-gray-400 cursor-not-allowed hover:shadow-none hover:scale-y-100"
          : ""
      }`}
      disabled={disabled}
      {...props}
    >
      {!IconComponent ? (
        ""
      ) : (
        // <GoDownload className="mr-2" />
        <IconComponent className="mr-2" />
      )}{" "}
      {/* Render the icon if provided */}
      {isLoading ? loader ?? "loading..." : buttonText}
    </button>
  );
};

export default ButtonWithIcon;
