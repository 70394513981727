import {
  accessControlIcon,
  borrowIcon,
  dashboardIcon,
  // dashboardIcon,
  disposalIcon,
  inventoryIcon,
  marketplaceIcon,
  notificationsIcon,
  organizationsIcon,
  settingsIcon,
  // supportIcon,
  transactionsIcon,
} from "../assets/icons";

export const menus = [
  // { title: "Home", path: "/", isHighlighted: false, forTop: true },
  // { title: "Categories", path: "#", isHighlighted: false, forTop: true },
  { title: "Home", path: "/", isHighlighted: false, forTop: true },
  { title: "Event", path: "/events", isHighlighted: false, forTop: true },
  {
    title: "Upcoming Event",
    path: "/upcoming-events",
    isHighlighted: false,
    forTop: true,
  },
  // {
  //   title: "New Listing",
  //   path: "/new-listing",
  //   isHighlighted: false,
  //   forTop: true,
  // },
  {
    title: "Contact Us",
    path: "/contact-us",
    isHighlighted: false,
    forTop: true,
  },
  // { title: "Sign In", path: "/login", isHighlighted: true, forTop: true },
];

export const footerMenu = [
  { title: "About", path: "/about-us", isHighlighted: false, forTop: true },
  { title: "Press", path: "/press", isHighlighted: false, forTop: true },
];

export const footerMenu2 = [
  { title: "FAQ", path: "/faq", isHighlighted: false, forTop: true },
  {
    title: "Privacy Policy",
    path: "/privacy-policy",
    isHighlighted: false,
    forTop: true,
  },
  {
    title: "Terms and Conditions",
    path: "/terms-and-conditions",
    isHighlighted: false,
    forTop: true,
  },
];

export const dashboardMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardIcon,
  },
  {
    title: "Inventory",
    path: "/inventory",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_stock"],
    subMenu: [
      {
        title: "Warehouse",
        path: "/warehouse",
        icon: marketplaceIcon,
        activeIcon: marketplaceIcon,
        requiredPermissions: ["view_warehouse"],
      },
      {
        title: "Projects",
        path: "/projects",
        icon: inventoryIcon,
        activeIcon: inventoryIcon,
        requiredPermissions: ["view_project"],
      },
    ],
  },
  {
    title: "Disposal",
    path: "/disposal",
    icon: disposalIcon,
    activeIcon: disposalIcon,
    requiredPermissions: ["view_stockdisposal"],
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    icon: marketplaceIcon,
    activeIcon: marketplaceIcon,
    requiredPermissions: ["view_stocksale"],
    subMenu: [
      {
        title: "Borrow",
        path: "/borrow",
        icon: borrowIcon,
        activeIcon: borrowIcon,
        requiredPermissions: ["view_stockborrow"],
      },
      {
        title: "Returns",
        path: "/returns",
        icon: borrowIcon,
        activeIcon: borrowIcon,
        requiredPermissions: ["view_stockreturn"],
      },
    ],
  },
  {
    title: "Tasks",
    path: "/tasks",
    icon: borrowIcon,
    activeIcon: borrowIcon,
    requiredPermissions: ["view_inspection", "view_inspectionreport"],
    subMenu: [
      {
        title: "Inspections",
        path: "/inspections",
        icon: borrowIcon,
        activeIcon: borrowIcon,
        requiredPermissions: ["view_inspection"],
      },
    ],
  },
  {
    title: "Auction Events",
    path: "/auction",
    icon: inventoryIcon,
    activeIcon: inventoryIcon,
    requiredPermissions: ["view_auction"],
    subMenu: [
      {
        title: "Lots",
        path: "/lots",
        icon: inventoryIcon,
        activeIcon: inventoryIcon,
        requiredPermissions: ["view_lot"],
      },
      {
        title: "Auction Items",
        path: "/approved-items",
        icon: inventoryIcon,
        activeIcon: inventoryIcon,
        requiredPermissions: ["view_auction"],
      },
    ],
  },
  {
    title: "Transactions",
    path: "/transactions",
    icon: transactionsIcon,
    activeIcon: transactionsIcon,
    requiredPermissions: ["view_transaction"],
  },

  // {
  //   title: "Chat",
  //   path: "/chat",
  //   icon: chatIcon,
  //   activeIcon: chatIcon,
  // },
  // {
  //   title: "Support",
  //   path: "/support",
  //   icon: supportIcon,
  //   activeIcon: supportIcon,
  // },
];

export const lowerMenu = [
  {
    title: "Access Control",
    path: "/access-control",
    icon: accessControlIcon,
    activeIcon: accessControlIcon,
    requiredPermissions: ["view_staff"],
  },
  {
    title: "Clients",
    path: "/clients",
    icon: organizationsIcon,
    activeIcon: organizationsIcon,
    requiredPermissions: ["view_client"],
  },
  {
    title: "Asset Groups",
    path: "/asset-groups",
    icon: organizationsIcon,
    activeIcon: organizationsIcon,
    requiredPermissions: ["view_assetgroup"],
  },
  {
    title: "Notifications",
    path: "/notifications",
    icon: notificationsIcon,
    activeIcon: notificationsIcon,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: settingsIcon,
    activeIcon: settingsIcon,
  },
];
