import usePostManager from "../../../constants/controller_templates/post_controller_template";

export const UploadCartReceiptManager = (id) => {
  const { postCaller, data, isLoading, isSuccess, error } = usePostManager(
    `/lots/transactions/receipts/upload`,
    ["cart"]
  );

  const submitReceipt = async (details) => {
    try {
      await postCaller(details);
    } catch (error) {
      console.error("error:", error);
    }
  };

  return {
    submitReceipt,
    data,
    isLoading,
    isSuccess,
    error,
  };
};
