import React from "react";
import HeaderFooter from "../../../generalComponents/HeaderFooter";
import EventTiles from "../components/EventTiles";
import useGetAuctionManager from "../../auctionEvents/controllers/getAuctionController";
import Loader from "../../../generalComponents/Loader";
import { noData } from "../../../assets/images";

const EventHome = () => {
  const { data, isLoading } = useGetAuctionManager({
    enabled: true,
    page: 1,
    status: true,
    inEvent: true,
  });

  return (
    <HeaderFooter>
      <div className="max-w-[1240px] w-full flex flex-col text-blackColor mx-auto">
        <div className="w-full justify-between items-center flex md:mt-10 mt-16 mb-3">
          <h3 className="text-28px font-semibold md:mx-0 mx-4">Events</h3>
        </div>
        {isLoading && (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <Loader />
          </div>
        )}
        {data?.data?.results.length > 0 ? (
          <div className="w-full grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-4 mb-20 md:mx-0 mx-4">
            {data?.data?.results.map((el, i) => (
              <EventTiles
                key={i}
                id={el?.id}
                eventName={el?.event_name}
                eventImage={el?.event_image_url}
                endDatetime={el?.end_datetime}
                lotsCount={el?.lots_count}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full mt-6">
            <img src={noData} alt="NoData" width={"120px"} />
            <span className="text-20px font-medium text-brandGreen">
              No Live Events
            </span>
          </div>
        )}
      </div>
    </HeaderFooter>
  );
};

export default EventHome;
