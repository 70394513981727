import { useQuery } from "react-query";
import AxiosWithToken from "../../../constants/api_management/MyHttpHelperWithToken";

const useGetBidsManager = ({ enabled, participant, is_active }) => {
  return useQuery(["bid", is_active, participant], async () => {
    try {
      const [response] = [
        await AxiosWithToken.get(
          `/bids/?is_participant_bid=${participant}&is_active=${is_active}`
        ),
      ];
      //console.log(`i am checking this ${response.status}`);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      throw new Error(`Sorry: ${error.response.data.message}`);
    }
  });
};

export default useGetBidsManager;
