import React from "react";
import Tabs from "../../../generalComponents/Tabs";
import BaseDashboardNavigation from "../../../generalComponents/BaseDashboardNavigation";
import { useLocation } from "react-router-dom";
import GoBackButton from "../../../generalComponents/GoBackButton";
import StatusButton from "../../../generalComponents/StatusButton";
import useGetDisposalBatchByIdManager from "../../disposal/disposalBatches/controllers/getDisposalBatchByIdController";
import AuctionItemsByRequestId from "./AuctionItemsByRequestId";

const AuctionItemsList = () => {
  const location = useLocation();
  const itemId = location.state?.itemId;
  const approvalStatus = location.state?.approvalStatus;

  const { data: batchDetails } = useGetDisposalBatchByIdManager(itemId);

  const requestData = batchDetails?.data;

  const tabsData = [
    {
      label: "Batch List",
      component: (
        <AuctionItemsByRequestId
          requestLetter={requestData?.request_letter_url}
          requestStatus={requestData?.request_status}
          approvalStatus={approvalStatus}
        />
      ),
    },
  ];

  return (
    <BaseDashboardNavigation>
      <div>
        <GoBackButton />
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-full pt-3">
          <div className="flex items-center mb-2 md:mb-0 gap-x-2">
            <h3 className="text-[24px] font-bold">
              Request ID: {requestData?.request_id}{" "}
            </h3>
            <StatusButton status={requestData?.approval_status} />
          </div>
        </div>

        <Tabs tabsData={tabsData} />
      </div>
    </BaseDashboardNavigation>
  );
};

export default AuctionItemsList;
