import { useState, useEffect } from "react";
import ModalManagement from "../../../generalComponents/ModalManagement";
import InputWithFullBoarder from "../../../generalComponents/InputWithFullBoarder";
import ButtonWithIcon from "../../../generalComponents/ButtonWithIcon";
import { BiPlus } from "react-icons/bi";
import SelectWithFullBorder from "../../../generalComponents/SelectWithFullBorder";
import useGetStockManager from "../../inventory/stockItems/controllers/getStockController";
import { CreateExchangeManager } from "../controllers/createExchangeController";
import useGetWarehouseManager from "../../warehouse/controllers/getWarehouseController";

const ExchangeModal = ({ stockId, assetName }) => {
  const [stockQuantity, setStockQuantity] = useState(0);
  const [selectedExchangeAsset, setSelectedExchangeAsset] = useState();
  const [exchangeQuantity, setExchangeQuantity] = useState(0);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const { data: stockItems } = useGetStockManager({
    allocation: "STOCK",
    page: "",
  });

  const { createExchange, isSuccess } = CreateExchangeManager();

  const { data: wareHouses } = useGetWarehouseManager({
    enabled: true,
    page: "",
  });

  const handleTransferAsset = () => {
    const payload = {
      stock: stockId,
      quantity: stockQuantity,
      exchange_stock: selectedExchangeAsset,
      exchange_stock_quantity: exchangeQuantity,
      warehouse: selectedWarehouse,
    };

    createExchange(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      document.getElementById("exchange_asset").close();
    }
  }, [isSuccess]);

  return (
    <div>
      <ModalManagement id={"exchange_asset"} title={"Exchange Asset"}>
        <div className="md:w-[599px] h-auto rounded-[6px] relative bg-white flex flex-col pt-6">
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Asset Name"}
                value={assetName}
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={stockQuantity}
                onChange={(e) => setStockQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Select Exchange Asset"}
                selectOptions={[
                  { label: "Select Exchange Asset", value: "" },
                  ...(stockItems?.data?.results?.map((stock) => ({
                    label: stock?.asset_name,
                    value: stock?.stock_id,
                  })) || []),
                ]}
                value={selectedExchangeAsset}
                onChange={(e) => setSelectedExchangeAsset(e.target.value)}
                placeholder="Select Exchange Asset"
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Exchange Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={exchangeQuantity}
                onChange={(e) => setExchangeQuantity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                selectOptions={[
                  { label: "Select Warehouse", value: "" },
                  ...(wareHouses?.data?.results?.map((warehouse) => ({
                    label: warehouse?.name,
                    value: warehouse?.id,
                  })) || []),
                ]}
                value={selectedWarehouse}
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                placeholder="Select Warehouse"
                label="Select Warehouse"
                required
              />
            </div>
          </div>

          {/* <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <SelectWithFullBorder
                label={"Transfer to:"}
                selectOptions={transferOptions}
                value={selectedTransferOption}
                onChange={(e) => setSelectedTransferOption(e.target.value)}
                placeholder="Transfer to:"
              />
            </div>
          </div>
          <div className="flex-1">
            <SelectWithFullBorder
              label={"Select Item to Exchange with"}
              // selectOptions={[
              //   ...projects?.data?.results?.map((project) => ({
              //     label: project.name,
              //     value: project.id,
              //   })),
              // ]}
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              placeholder="Project to transfer to"
            />
          </div>

          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="flex-1">
              <InputWithFullBoarder
                label={"Quantity"}
                placeholder={"Enter Quantity"}
                type={"number"}
                value={transferQuantity}
                onChange={(e) => setTransferQuantity(e.target.value)}
                required
              />
            </div>
          </div> */}
          <div className="flex flex-col md:flex-row md:space-x-2 justify-end">
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Cancel"}
              textColor={"#344054"}
              className={"bg-white border border-gray-600 h-[40px] w-auto"}
              onClick={() => {
                document.getElementById("exchange_asset").close();
              }}
            />
            <ButtonWithIcon
              icon={BiPlus}
              buttonText={"Exchange Asset"}
              onClick={handleTransferAsset}
            />
          </div>
        </div>
      </ModalManagement>
    </div>
  );
};

export default ExchangeModal;
